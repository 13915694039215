<template>
    <div>
        <h1>Daily Curb Count</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from "@/store/Report.store.js";
    import Options from "./daily_curb_count_opt";
    import Results from "./utils/Results";


    export default {
        name: 'daily_curb_count',
        components: {
            Results,
            Options
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    fromDate: {val: new Date().toLocaleDateString(), qsField: "[start_date]" },
                    dayCount: {val: '3', qsField: "[num_days]"},
                    cdiLocationID: {val: '1', qsField: '[cdi_location_id]'},
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Daily Curb Count']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>